import { entityStatusConstants } from './entityStatusConstants';
import { userConstants } from "../../auth/_redux/authConstants";

const makeInitialState = () => ({
  requestActive: false,
  requestSaveActive: false,
  shouldReload: true,
  items: [],
  rowsPerPage: 10,
  page: 1,
  sortBy: "id",
  sortDirection: "desc",
  total: 0,
  error: '',
  type: '',
  locationIds: null,
  tenantIds: null,
  inProgress: true,
  query: ''
});

export function entity_statuses(state = makeInitialState(), action) {
  switch (action.type) {
    case userConstants.LOGOUT:
      return makeInitialState();
    case entityStatusConstants.ENTITY_STATUS_LIST_REQUEST:
      return {
        ...state,
        requestActive: true,
        shouldReload: false,
        error: '',
        total: 0,
        inProgress: true,
      };
    case entityStatusConstants.ENTITY_STATUS_LIST_SUCCESS: {
      return {
        ...state,
        requestActive: false,
        shouldReload: false,
        items: action.entityStatuses.rows ? action.entityStatuses.rows : [],
        total: action.entityStatuses.total ? action.entityStatuses.total : 0,
        inProgress: false,
      }
    }
    case entityStatusConstants.ENTITY_STATUS_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        inProgress: false,
      };
    case entityStatusConstants.ENTITY_STATUS_LIST_CHANGED:
      return {
        ...state,
        shouldReload: true,
      };
    case entityStatusConstants.ENTITY_STATUS_LIST_CLEAR_PAGINATION:
      return makeInitialState();
    case entityStatusConstants.ENTITY_STATUS_LIST_PAGINATION: {
      let shouldReload = state.items.length === 0 ? true : false;

      if (state.rowsPerPage !== action.pagination.rowsPerPage
        || state.page !== action.pagination.page
        || state.sortBy !== action.pagination.sortBy
        || state.sortDirection !== action.pagination.sortDirection
        || state.shouldReload
        || state.type !== action.pagination.type
        || state.locationIds !== action.pagination.locationIds
        || state.tenantIds !== action.pagination.tenantIds
        || state.query !== action.pagination.query) {
        shouldReload = true;
      }

      return {
        ...state,
        rowsPerPage: action.pagination.rowsPerPage,
        page: action.pagination.page,
        sortBy: action.pagination.sortBy,
        sortDirection: action.pagination.sortDirection,
        type: action.pagination.type,
        locationIds: action.pagination.locationIds,
        tenantIds: action.pagination.tenantIds,
        shouldReload,
        query: action.pagination.query,
      };
    }

    default:
      return state
  }
}
