import React from 'react';

const Tenant = React.lazy(() => import('../views/tenant/Tenant'));
const Tenants = React.lazy(() => import('../views/tenant/Tenants'));

const TenantsGroups = React.lazy(() => import('../views/tenant-groups/TenantsGroups'));

const Location = React.lazy(() => import('../views/location/Location'));
const Locations = React.lazy(() => import('../views/location/Locations'));

const LocationsGroups = React.lazy(() => import('../views/location-groups/LocationsGroups'));
const LocationsImport = React.lazy(() => import('../views/location/LocationsImport'));

const Slot = React.lazy(() => import('../views/slot/Slot'));
const Slots = React.lazy(() => import('../views/slot/Slots'));
const SlotsDemo = React.lazy(() => import('../views/slot/SlotsDemo'));
const SlotsOffline = React.lazy(() => import('../views/slot/SlotsOffline'));
const BulkActions = React.lazy(() => import('../views/slot/BulkActions'));
const SlotsGroups = React.lazy(() => import('../views/slot-groups/SlotsGroups'));
const SlotsImport = React.lazy(() => import('../views/slot/SlotsImport'));
const SlotControl = React.lazy(() => import('../views/slot/SlotControl'));
const MessageMeters = React.lazy(() => import('../views/message-meters/MessageMeters'));

const EntityGroup = React.lazy(() => import('../views/entity-group/EntityGroup'));
const EntityGroups = React.lazy(() => import('../views/entity-group/EntityGroups'));

const SlotGames = React.lazy(() => import('../views/slot-games/SlotGames'));

const AdvancedPage = React.lazy(() => import('../views/advanced/Advanced'));
const ReportsPage = React.lazy(() => import('../views/reports/Reports'));

const SMIB = React.lazy(() => import('../views/smib/Smib'));
const SMIBs = React.lazy(() => import('../views/smib/Smibs'));
const SmibControl = React.lazy(() => import('../views/smib/SmibControl'));
const SmibsImport = React.lazy(() => import('../views/smib/SmibsImport'));

const SlotConfig = React.lazy(() => import('../views/slot/SlotConfig'));
const SmibConfig = React.lazy(() => import('../views/smib/SmibConfig'));
const TenantConfig = React.lazy(() => import('../views/tenant/TenantConfig'));
const LocationConfig = React.lazy(() => import('../views/location/LocationConfig'));

const Messages = React.lazy(() => import('../views/messages/Messages'));
const Events = React.lazy(() => import('../views/events/Events'));
const Counters = React.lazy(() => import('../views/counters/Counters'));
const SlotData = React.lazy(() => import('../views/slotdata/SlotData'));

const Jackpot = React.lazy(() => import('../views/jackpots/Jackpot'));
const Jackpots = React.lazy(() => import('../views/jackpots/Jackpots'));

const JackpotHistory = React.lazy(() => import('../views/jackpot-history/JackpotHistory'));

const JackpotSource = React.lazy(() => import('../views/jackpot-sources/JackpotSource'));
const JackpotSources = React.lazy(() => import('../views/jackpot-sources/JackpotSources'));

const DisplayChannel = React.lazy(() => import('../views/display-channels/DisplayChannel')); 
const DisplayChannels = React.lazy(() => import('../views/display-channels/DisplayChannels'));

const Timelog = React.lazy(() => import('../views/timelog/Timelog'));

const Checkpoints = React.lazy(() => import('../views/checkpoint/Checkpoints'));
const Checkpoint = React.lazy(() => import('../views/checkpoint/Checkpoint'));
const Aggregations = React.lazy(() => import('../views/aggregation/Aggregations'));

const Tasks = React.lazy(() => import('../views/task/Tasks'));
const Dataexports = React.lazy(() => import('../views/dataexport/Dataexports'));
const RawAggregations = React.lazy(() => import('../views/rawaggregation/RawAggregations'));

const Logs = React.lazy(() => import('../views/log/Logs'));
const LogErrors = React.lazy(() => import('../views/log-error/LogErrors'));

const AclPage = React.lazy(() => import('../views/acl/Acl'));

const AclUser = React.lazy(() => import('../views/acl/user/User'));
const AclUsers = React.lazy(() => import('../views/acl/user/Users'));

const AclEntityGroup = React.lazy(() => import('../views/acl/entity-group/EntityGroup'));
const AclEntityGroups = React.lazy(() => import('../views/acl/entity-group/EntityGroups'));

const AclPermission = React.lazy(() => import('../views/acl/permission/Permission'));
const AclPermissions = React.lazy(() => import('../views/acl/permission/Permissions'));

const AclUserGroup = React.lazy(() => import('../views/acl/user-group/UserGroup'));
const AclUserGroups = React.lazy(() => import('../views/acl/user-group/UserGroups'));

const AclRole = React.lazy(() => import('../views/acl/role/Role'));
const AclRoles = React.lazy(() => import('../views/acl/role/Roles'));

const AclRule = React.lazy(() => import('../views/acl/rule/Rule'));
const AclRules = React.lazy(() => import('../views/acl/rule/Rules'));

const ApiToken = React.lazy(() => import('../views/acl/api-token/ApiToken'));
const ApiTokens = React.lazy(() => import('../views/acl/api-token/ApiTokens'));

const AdminPage = React.lazy(() => import('../views/admin/Admin'));

const AdminAggregationRule = React.lazy(() => import('../views/admin/aggregation-rule/AggregationRule'));
const AdminAggregationRules = React.lazy(() => import('../views/admin/aggregation-rule/AggregationRules'));

const AdminColumnsConfigurations = React.lazy(() => import('../views/admin/columns-configuration/ColumnsConfigurations'));
const AdminColumnsConfiguration = React.lazy(() => import('../views/admin/columns-configuration/ColumnsConfiguration'));

const AdminGeneralSettings = React.lazy(() => import('../views/admin/general-settings/GeneralSettings'));

const ReportsPaymentTransactions = React.lazy(() => import('../views/reports/payment-transactions/PaymentTransactions'));
const ReportsPaymentTransaction = React.lazy(() => import('../views/reports/payment-transactions/PaymentTransaction'));

const ReportsCollectorCheckpoints = React.lazy(() => import('../views/reports/collector-checkpoints/CollectorCheckpoints'));
const ReportsCollectorCheckpoint = React.lazy(() => import('../views/reports/collector-checkpoints/CollectorCheckpoint'));

const ReportsExtendedMeters = React.lazy(() => import('../views/reports/extended-meters/ExtendedMeters'));

const Dashboard = React.lazy(() => import('../views/dashboard/Dashboard'));
const Access = React.lazy(() => import('../views/Access'));

const DailyReports = React.lazy(() => import('../views/reports/daily-legal/DailyReports'));

const MonthlyReports = React.lazy(() => import('../views/reports/monthly-legal/MonthlyReports'));

const OperationsPage = React.lazy(() => import('../views/operations/Operations'));
const RemoteCashout = React.lazy(() => import('../views/operations/remote-cashout/RemoteCashout'));

const NotificationsPage = React.lazy(() => import('../views/notifications/Notifications'));
const NotificationsSubscriptions = React.lazy(() => import('../views/notifications/subscriptions/Subscriptions'));
const NotificationsList = React.lazy(() => import('../views/notifications/list/List'));

const PlayerExclusionPage = React.lazy(() => import('../views/player-exclusion/PlayerExclusion'));
const PlayerExclusionPlayers = React.lazy(() => import('../views/player-exclusion/players/Players'));
const PlayerExclusionPlayer = React.lazy(() => import('../views/player-exclusion/players/Player'));
const PlayerExclusionSelfExclusions = React.lazy(() => import('../views/player-exclusion/self-exclusions/SelfExclusions'));
const PlayerExclusionSelfExclusionsImport = React.lazy(() => import('../views/player-exclusion/self-exclusions/SelfExclusionsImport'));
const PlayerExclusionSelfExclusionsOnjnImport = React.lazy(() => import('../views/player-exclusion/self-exclusions/SelfExclusionsOnjnImport'));

const PlayerExclusionSelfExclusion = React.lazy(() => import('../views/player-exclusion/self-exclusions/SelfExclusion'));
const PlayerExclusionPlayerScans = React.lazy(() => import('../views/player-exclusion/player-scans/PlayerScans'));
const PlayerExclusionPlayerScan = React.lazy(() => import('../views/player-exclusion/player-scans/PlayerScan'));
const PlayerExclusionBlacklists = React.lazy(() => import('../views/player-exclusion/blacklists/Blacklists'));
const PlayerExclusionBlacklist = React.lazy(() => import('../views/player-exclusion/blacklists/Blacklist'));

const GamesRegistryPage = React.lazy(() => import('../views/games-registry/GameRegistry'));
const GamesRegistryMachineTypes = React.lazy(() => import('../views/games-registry/machine-types/MachineTypes'));
const GamesRegistryMachineType = React.lazy(() => import('../views/games-registry/machine-types/MachineType'));
const GamesRegistryGames = React.lazy(() => import('../views/games-registry/games/Games'));
const GamesRegistryGame = React.lazy(() => import('../views/games-registry/games/Game'));
const GamesRegistryGameMixes = React.lazy(() => import('../views/games-registry/game-mixes/GameMixes'));
const GamesRegistryGameMix = React.lazy(() => import('../views/games-registry/game-mixes/GameMix'));
const GamesRegistryGameProfiles = React.lazy(() => import('../views/games-registry/game-profiles/GameProfiles'));
const GamesRegistryGameProfile = React.lazy(() => import('../views/games-registry/game-profiles/GameProfile'));
const GamesRegistryManufacturers = React.lazy(() => import('../views/games-registry/manufacturers/Manufacturers'));
const GamesRegistryManufacturer = React.lazy(() => import('../views/games-registry/manufacturers/Manufacturer'));
const GamesRegistryProtocols = React.lazy(() => import('../views/games-registry/protocols/Protocols'));
const GamesRegistryProtocol = React.lazy(() => import('../views/games-registry/protocols/Protocol'));


export const routes = [
  {
    path: "/dashboard",
    exact: true,
    component: Dashboard,
    columnsConfigurable: false
  },
  {
    path: "/dashboard/:action",
    exact: true,
    component: Dashboard,
    columnsConfigurable: false
  },
  {
    path: "/access",
    exact: true,
    component: Access,
    columnsConfigurable: false
  },
  {
    path: "/admin",
    exact: true,
    component: AdminPage,
    columnsConfigurable: true
  },
  {
    path: "/admin/columns-configurations/:id",
    exact: true,
    component: AdminColumnsConfiguration,
    name: "Page Columns Configuration",
    columnsConfigurable: false
  },
  {
    path: "/admin/columns-configurations",
    exact: true,
    component: AdminColumnsConfigurations,
    name: "Columns Configurations",
    columnsConfigurable: false
  },
  {
    path: "/admin/general-settings",
    exact: true,
    component: AdminGeneralSettings,
    name: "General Settings",
    columnsConfigurable: false
  },
  {
    path: "/reports",
    exact: true,
    component: ReportsPage,
    columnsConfigurable: false
  },
  {
    path: "/reports/payment-transactions",
    exact: true,
    component: ReportsPaymentTransactions,
    name: "Payment Transactions",
    columnsConfigurable: false
  },
  {
    path: "/reports/payment-transaction",
    exact: true,
    component: ReportsPaymentTransaction,
    name: "Payment Transaction",
    columnsConfigurable: false
  },
  {
    path: "/reports/collector-checkpoints",
    exact: true,
    component: ReportsCollectorCheckpoints,
    name: "Collector Checkpoints",
    columnsConfigurable: false
  },
  {
    path: "/reports/collector-checkpoints/:id",
    exact: true,
    component: ReportsCollectorCheckpoint,
    name: "Collector Checkpoint",
    columnsConfigurable: false
  },
  {
    path: "/acl",
    exact: true,
    component: AclPage,
    columnsConfigurable: false
  },
  {
    path: "/acl/users/:id",
    exact: true,
    component: AclUser,
    columnsConfigurable: false
  },
  {
    path: "/acl/users",
    exact: true,
    component: AclUsers,
    name: "Users",
    columnsConfigurable: false
  },
  {
    path: "/acl/users/:id",
    exact: true,
    component: AclUsers,
    name: "Users",
    columnsConfigurable: false
  },
  {
    path: "/acl/entity-groups/:id",
    exact: true,
    component: AclEntityGroup,
    columnsConfigurable: false
  },
  {
    path: "/acl/entity-groups",
    exact: true,
    component: AclEntityGroups,
    name: "Entity Groups",
    columnsConfigurable: false
  },
  {
    path: "/acl/permissions/:id",
    exact: true,
    component: AclPermission,
    columnsConfigurable: false
  },
  {
    path: "/acl/permissions",
    exact: true,
    component: AclPermissions,
    name: "Permissions",
    columnsConfigurable: false
  },
  {
    path: "/acl/user-groups/:id",
    exact: true,
    component: AclUserGroup,
    columnsConfigurable: false
  },
  {
    path: "/acl/user-groups",
    exact: true,
    component: AclUserGroups,
    name: "User Groups",
    columnsConfigurable: false
  },
  {
    path: "/acl/roles/:id",
    exact: true,
    component: AclRole,
    columnsConfigurable: false
  },
  {
    path: "/acl/roles",
    exact: true,
    component: AclRoles,
    name: "Roles",
    columnsConfigurable: false
  },
  {
    path: "/acl/rules/:id",
    exact: true,
    component: AclRule,
    columnsConfigurable: false
  },
  {
    path: "/acl/rules",
    exact: true,
    component: AclRules,
    name: "Rules",
    columnsConfigurable: false
  },
  {
    path: "/acl/api-tokens/:id",
    exact: true,
    component: ApiToken,
    columnsConfigurable: false
  },
  {
    path: "/acl/api-tokens",
    exact: true,
    component: ApiTokens,
    name: "Api Tokens",
    columnsConfigurable: false
  },
  {
    path: "/tenants/:id",
    exact: true,
    component: Tenant,
    columnsConfigurable: false
  },
  {
    path: "/tenants",
    exact: true,
    component: Tenants,
    name: "Tenants",
    columnsConfigurable: true
  },
  {
    path: "/tenants-groups",
    exact: true,
    component: TenantsGroups,
    name: "TenantsGroups",
    columnsConfigurable: true
  },
  {
    path: "/locations/:id",
    component: Location,
    exact: true,
    columnsConfigurable: false
  },
  {
    path: "/locations",
    exact: true,
    component: Locations,
    name: "Locations",
    columnsConfigurable: true
  },
  {
    path: "/locations-groups",
    exact: true,
    component: LocationsGroups,
    name: "LocationsGroups",
    columnsConfigurable: true
  },
  {
    path: "/locations-import/",
    exact: true,
    component: LocationsImport,
    name: "LocationsImport",
    columnsConfigurable: true
  },
  {
    path: "/slots/:id",
    component: Slot,
    exact: true,
    columnsConfigurable: false
  },
  {
    path: "/slots-groups",
    exact: true,
    component: SlotsGroups,
    name: "SlotsGroups",
    columnsConfigurable: true
  },
  {
    path: "/slots-import/",
    exact: true,
    component: SlotsImport,
    name: "SlotsImport",
    columnsConfigurable: true
  },
  {
    path: "/message-meters",
    exact: true,
    component: MessageMeters,
    name: "MessageMeters",
    columnsConfigurable: true
  },
  {
    path: "/entity-groups/:id",
    exact: true,
    component: EntityGroup,
    columnsConfigurable: false
  },
  {
    path: "/entity-groups",
    exact: true,
    component: EntityGroups,
    name: "Entity Groups",
    columnsConfigurable: false
  },
  {
    path: "/reports/slot-games",
    component: SlotGames,
    exact: true,
  },
  {
    path: "/reports/slot-games?slot_id=:id",
    component: SlotGames,
    exact: true,
  },
  {
    path: "/slots",
    component: Slots,
    name: "Slots",
    columnsConfigurable: true
  },
  {
    path: "/slots-demo",
    component: SlotsDemo,
    name: "Slots Demo",
    columnsConfigurable: true
  },
  {
    path: "/slots-offline",
    component: SlotsOffline,
    name: "Slots Offline",
    columnsConfigurable: true
  },
  {
    path: "/bulk-actions",
    component: BulkActions,
    name: "Bulk Actions",
    columnsConfigurable: true
  },
  {
    path: "/slot/control/:id",
    component: SlotControl,
    exact: true,
    columnsConfigurable: false
  },
  {
    path: "/messages/:id",
    exact: true,
    component: Messages,
    columnsConfigurable: false
  },
  {
    path: "/messages",
    exact: true,
    component: Messages,
    name: "Messages",
    columnsConfigurable: true
  },
  {
    path: "/events/:id",
    exact: true,
    component: Events,
    name: "Events",
    columnsConfigurable: true
  },
  {
    path: "/events",
    exact: true,
    component: Events,
    name: "Events",
    columnsConfigurable: true
  },
  {
    path: "/counters/:id",
    exact: true,
    component: Counters,
    columnsConfigurable: false
  },
  {
    path: "/counters",
    exact: true,
    component: Counters,
    name: "Counters",
    columnsConfigurable: true
  },
  {
    path: "/slotdata/:id",
    exact: true,
    component: SlotData,
    columnsConfigurable: false
  },
  {
    path: "/slotdata",
    exact: true,
    component: SlotData,
    name: "SlotData",
    columnsConfigurable: true
  },
  {
    path: "/counters/virtual/:id",
    exact: true,
    component: SlotConfig,
    columnsConfigurable: false
  },
  {
    path: "/counters/logic/:id",
    exact: true,
    component: SlotConfig,
    columnsConfigurable: false
  },
  {
    path: "/config/tenant/:id",
    exact: true,
    component: TenantConfig,
    columnsConfigurable: false
  },
  {
    path: "/config/location/:id",
    exact: true,
    component: LocationConfig,
    columnsConfigurable: false
  },
  {
    path: "/config/smib/:id",
    exact: true,
    component: SmibConfig,
    columnsConfigurable: false
  },
  {
    path: "/advanced",
    exact: true,
    component: AdvancedPage,
    columnsConfigurable: false
  },
  {
    path: "/advanced/smibs/:id",
    exact: true,
    component: SMIB,
    columnsConfigurable: false
  },
  {
    path: "/advanced/smibs",
    exact: true,
    component: SMIBs,
    name: "SMIBs",
    columnsConfigurable: true
  },
  {
    path: "/advanced/smibs-import/",
    exact: true,
    component: SmibsImport,
    columnsConfigurable: false
  },
  {
    path: "/advanced/smib",
    exact: true,
    component: AdvancedPage,
    columnsConfigurable: false
  },
  {
    path: "/advanced/smib/control",
    exact: true,
    component: AdvancedPage,
    columnsConfigurable: false
  },
  {
    path: "/advanced/smib/control/:id",
    component: SmibControl,
    exact: true,
    columnsConfigurable: false
  },
  {
    path: "/advanced/tasks",
    exact: true,
    component: Tasks,
    name: "Tasks",
    columnsConfigurable: true
  },
  {
    path: "/advanced/aggregation-rules/:id",
    exact: true,
    component: AdminAggregationRule,
    columnsConfigurable: false
  },
  {
    path: "/advanced/aggregation-rules",
    exact: true,
    component: AdminAggregationRules,
    name: "Aggregation Rules",
    columnsConfigurable: true
  },
  {
    path: "/advanced/timelog",
    exact: true,
    component: Timelog,
    columnsConfigurable: false
  },
  {
    path: "/advanced/timelog/:type",
    exact: true,
    component: Timelog,
    columnsConfigurable: false
  },
  {
    path: "/advanced/timelog/:type/:id",
    exact: true,
    component: Timelog,
    columnsConfigurable: false
  },
  {
    path: "/checkpoint/:type/:id",
    exact: true,
    component: Checkpoint,
    columnsConfigurable: false
  },
  {
    path: "/advanced/checkpoints",
    exact: true,
    component: Checkpoints,
    columnsConfigurable: false
  },
  {
    path: "/advanced/checkpoints/:type",
    exact: true,
    component: Checkpoints,
    columnsConfigurable: false
  },
  {
    path: "/advanced/checkpoints/:type/:id",
    exact: true,
    component: Checkpoints,
    columnsConfigurable: false
  },
  // {
  //   path: "/advanced/computed-aggregations",
  //   exact: true,
  //   component: Aggregations,
  //   columnsConfigurable: false
  // },
  // {
  //   path: "/advanced/computed-aggregations/:type",
  //   exact: true,
  //   component: Aggregations,
  //   columnsConfigurable: false
  // },
  // {
  //   path: "/advanced/computed-aggregations/:type/:id",
  //   exact: true,
  //   component: Aggregations,
  //   columnsConfigurable: false
  // },
  {
    path: "/data-exports",
    exact: true,
    component: Dataexports,
    name: "Data Exports",
    columnsConfigurable: true
  },
  {
    path: "/jackpots/:id",
    exact: true,
    variant: true,
    component: Jackpot,
    columnsConfigurable: false
  },
  {
    path: "/jackpots",
    exact: true,
    component: Jackpots,
    name: "Jackpots",
    columnsConfigurable: true
  },
  {
    path: "/jackpot-history",
    exact: true,
    component: JackpotHistory,
    name: "Jackpot History",
    columnsConfigurable: true
  },
  {
    path: "/jackpot-history/:type",
    exact: true,
    component: JackpotHistory,
    name: "Jackpot History",
    columnsConfigurable: true
  },
  {
    path: "/jackpot-history/:type/:id",
    exact: true,
    component: JackpotHistory,
    name: "Jackpot History",
    columnsConfigurable: true
  },
  {
    path: "/jackpot-sources/:id",
    exact: true,
    variant: true,
    component: JackpotSource,
    columnsConfigurable: false
  },
  {
    path: "/jackpot-sources",
    exact: true,
    component: JackpotSources,
    name: "Jackpot Sources",
    columnsConfigurable: true
  },
  {
    path: "/display-channels",
    exact: true,
    component: DisplayChannels,
    name: "Display Channels",
    columnsConfigurable: true
  },
  {
    path: "/display-channel/:id",
    exact: true,
    component: DisplayChannel,
    name: "Display Channel",
    columnsConfigurable: true
  },
  {
    path: '/advanced/raw-aggregations',
    exact: true,
    component: RawAggregations,
    columnsConfigurable: false
  },
  {
    path: '/advanced/raw-aggregations/:type',
    exact: true,
    component: RawAggregations,
    columnsConfigurable: false
  },
  {
    path: '/advanced/raw-aggregations/:type/:id',
    exact: true,
    component: RawAggregations,
    columnsConfigurable: false
  },
  {
    path: "/logs/:id",
    exact: true,
    component: Logs,
    columnsConfigurable: false
  },
  {
    path: "/error-logs/:id",
    exact: true,
    component: LogErrors,
    columnsConfigurable: false
  },
  {
    path: "/reports/daily-reports",
    exact: true,
    component: DailyReports,
    columnsConfigurable: false
  },
  {
    path: "/reports/monthly-reports",
    exact: true,
    component: MonthlyReports,
    columnsConfigurable: false
  },
  {
    path: "/reports/extended-meters",
    exact: true,
    component: ReportsExtendedMeters,
    columnsConfigurable: false
  },
  // commented because it has a separate layout
  // {
  //   path: "/reports/daily-report/:id",
  //   exact: true,
  //   component: DailyReport,
  //   columnsConfigurable: false
  // },
  {
    path: "/operations",
    exact: true,
    component: OperationsPage,
    columnsConfigurable: false
  },
  {
    path: "/operations/remote-cashout",
    exact: true,
    component: RemoteCashout,
    columnsConfigurable: false
  },

  {
    path: "/notifications",
    exact: true,
    component: NotificationsPage,
    columnsConfigurable: false
  },
  {
    path: "/notifications/list",
    exact: true,
    component: NotificationsList,
    columnsConfigurable: false
  },
  {
    path: "/notifications/subscriptions",
    exact: true,
    component: NotificationsSubscriptions,
    columnsConfigurable: false
  },
  {
    path: "/player-exclusion",
    exact: true,
    component: PlayerExclusionPage,
    columnsConfigurable: false
  },
  {
    path: "/player-exclusion/players",
    exact: true,
    component: PlayerExclusionPlayers,
    columnsConfigurable: false
  },
  {
    path: "/player-exclusion/players/:id",
    exact: true,
    component: PlayerExclusionPlayer,
    columnsConfigurable: false
  },
  {
    path: "/player-exclusion/self-exclusions",
    exact: true,
    component: PlayerExclusionSelfExclusions,
    columnsConfigurable: false
  },
  {
    path: "/player-exclusion/self-exclusions-import/",
    exact: true,
    component: PlayerExclusionSelfExclusionsImport,
    columnsConfigurable: true
  },
  {
    path: "/player-exclusion/self-exclusions/:id",
    exact: true,
    component: PlayerExclusionSelfExclusion,
    columnsConfigurable: false
  },
  {
    path: "/player-exclusion/self-exclusions-onjn-import/",
    exact: true,
    component: PlayerExclusionSelfExclusionsOnjnImport,
    columnsConfigurable: true
  },
  {
    path: "/player-exclusion/player-scans",
    exact: true,
    component: PlayerExclusionPlayerScans,
    columnsConfigurable: false
  },
  {
    path: "/player-exclusion/player-scans/:id",
    exact: true,
    component: PlayerExclusionPlayerScan,
    columnsConfigurable: false
  },
  {
    path: "/player-exclusion/blacklists",
    exact: true,
    component: PlayerExclusionBlacklists,
    columnsConfigurable: false
  },
  {
    path: "/player-exclusion/blacklists/:id",
    exact: true,
    component: PlayerExclusionBlacklist,
    columnsConfigurable: false
  },
  {
    path: "/games-registry",
    exact: true,
    component: GamesRegistryPage,
    columnsConfigurable: false
  },
  {
    path: "/games-registry/machine-types",
    exact: true,
    component: GamesRegistryMachineTypes,
    columnsConfigurable: false
  },
  {
    path: "/games-registry/machine-types/:id",
    exact: true,
    component: GamesRegistryMachineType,
    columnsConfigurable: false
  },
  {
    path: "/games-registry/games",
    exact: true,
    component: GamesRegistryGames,
    columnsConfigurable: false
  },
  {
    path: "/games-registry/games/:id",
    exact: true,
    component: GamesRegistryGame,
    columnsConfigurable: false
  },
  {
    path: "/games-registry/game-mixes",
    exact: true,
    component: GamesRegistryGameMixes,
    columnsConfigurable: false
  },
  {
    path: "/games-registry/game-mixes/:id",
    exact: true,
    component: GamesRegistryGameMix,
    columnsConfigurable: false
  },
  {
    path: "/games-registry/game-profiles",
    exact: true,
    component: GamesRegistryGameProfiles,
    columnsConfigurable: false
  },
  {
    path: "/games-registry/game-profiles/:id",
    exact: true,
    component: GamesRegistryGameProfile,
    columnsConfigurable: false
  },
  {
    path: "/games-registry/manufacturers",
    exact: true,
    component: GamesRegistryManufacturers,
    columnsConfigurable: false
  },
  {
    path: "/games-registry/manufacturers/:id",
    exact: true,
    component: GamesRegistryManufacturer,
    columnsConfigurable: false
  },
  {
    path: "/games-registry/protocols",
    exact: true,
    component: GamesRegistryProtocols,
    columnsConfigurable: false
  },
  {
    path: "/games-registry/protocols/:id",
    exact: true,
    component: GamesRegistryProtocol,
    columnsConfigurable: false
  },

];

export function getDashboardItems() {
  let dashRoutes = [];

  routes.forEach(route => {
    if (route.name) dashRoutes.push(route);
  })

  return dashRoutes;
}

export function getColumnsConfigurableRoutes() {
  let columnsConfigurableRoutes = [];

  routes.forEach(route => {
    if (route.columnsConfigurable && !route.path.includes("/admin")) {
      route.path_stripped = route.path.replace("/", "");
      route.path_stripped = route.path_stripped.replaceAll("/", "_");
      route.path_stripped = route.path_stripped.replaceAll(":", "_");
      columnsConfigurableRoutes.push(route);
    }
  })

  return columnsConfigurableRoutes;
}
