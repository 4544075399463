import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import { useTranslation } from 'react-i18next';
import { hasSubMenuAccess } from "../MenuAccess";

export function HeaderMenuHome({ layoutProps }) {
  const { t } = useTranslation();
  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  }

  return <div
    id="kt_header_menu"
    className={`header-menu header-menu-left header-menu-mobile menu-submenu menu-submenu-classic menu-submenu-left ${layoutProps.ktMenuClasses}`}
    {...layoutProps.headerMenuAttributes}
  >
    <ul className={`menu-nav menu-subnav ${layoutProps.ulClasses}`}>
      {hasSubMenuAccess("/dashboard") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/dashboard')}`}>
          <NavLink className="menu-link" to="/dashboard">
            <span className="menu-text">{t("Dashboard")}</span>
          </NavLink>
        </li>
      )}
    </ul>

  </div>;
}

export function HeaderMenuMonitoring({ layoutProps }) {
  const location = useLocation();
  const { t } = useTranslation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  }

  return <div
    id="kt_header_menu"
    className={`header-menu header-menu-left header-menu-mobile menu-submenu menu-submenu-classic menu-submenu-left ${layoutProps.ktMenuClasses}`}
    {...layoutProps.headerMenuAttributes}
  >
    <ul className={`menu-nav menu-subnav ${layoutProps.ulClasses}`}>
      {hasSubMenuAccess("/tenants") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/tenants')}`}>
          <NavLink className="menu-link" to={{ pathname: "/tenants", key: Math.round(Math.random() * 1000), menuReload: true }}>
            <span className="menu-text">{t("Tenants")}</span>
          </NavLink>
        </li>
      )}
      {hasSubMenuAccess("/locations") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/locations')}`}>
          <NavLink className="menu-link" to={{ pathname: "/locations", key: Math.round(Math.random() * 1000), menuReload: true }}>
            <span className="menu-text">{t("Locations")}</span>
          </NavLink>
        </li>
      )}
      {hasSubMenuAccess("/slots") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/slots')}`}>
          <NavLink className="menu-link" to={{ pathname: "/slots", key: Math.round(Math.random() * 1000), menuReload: true }}>
            <span className="menu-text">{t("Slots")}</span>
          </NavLink>
        </li>
      )}
      {hasSubMenuAccess("/slots-demo") && false && ( //disabled
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/slots-demo')}`}>
          <NavLink className="menu-link" to={{ pathname: "/slots-demo", key: Math.round(Math.random() * 1000), menuReload: true }}>
            <span className="menu-text">{t("Slots Demo")}</span>
          </NavLink>
        </li>
      )}
      {hasSubMenuAccess("/slots-offline") && ( //disabled
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/slots-offline')}`}>
          <NavLink className="menu-link" to={{ pathname: "/slots-offline", key: Math.round(Math.random() * 1000), menuReload: true }}>
            <span className="menu-text">{t("Slots Offline")}</span>
          </NavLink>
        </li>
      )}
      {hasSubMenuAccess("/events") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/events')}`}>
          <NavLink className="menu-link" to={{ pathname: "/events", key: Math.round(Math.random() * 1000), menuReload: true }}>
            <span className="menu-text">{t("Events")}</span>
          </NavLink>
        </li>
      )}
      {hasSubMenuAccess("/entity-groups") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/entity-groups')}`}>
          <NavLink className="menu-link" to={{ pathname: "/entity-groups", key: Math.round(Math.random() * 1000), menuReload: true }}>
            <span className="menu-text">{t("Entity Groups")}</span>
          </NavLink>
        </li>
      )}
      {hasSubMenuAccess("/bulk-actions") && ( //disabled
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/bulk-actions')}`}>
          <NavLink className="menu-link" to={{ pathname: "/bulk-actions", key: Math.round(Math.random() * 1000), menuReload: true }}>
            <span className="menu-text">{t("Bulk Actions")}</span>
          </NavLink>
        </li>
      )}
    </ul>

  </div>;
}

export function HeaderMenuJackpots({ layoutProps }) {
  const location = useLocation();
  const { t } = useTranslation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  }

  return <div
    id="kt_header_menu"
    className={`header-menu header-menu-left header-menu-mobile menu-submenu menu-submenu-classic menu-submenu-left ${layoutProps.ktMenuClasses}`}
    {...layoutProps.headerMenuAttributes}
  >
    <ul className={`menu-nav menu-subnav ${layoutProps.ulClasses}`}>
      {hasSubMenuAccess("/jackpots") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/jackpots')}`}>
          <NavLink className="menu-link" to="/jackpots">
            <span className="menu-text">{t("Jackpots")}</span>
            {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
          </NavLink>
        </li>
      )}
      {hasSubMenuAccess("/jackpot-history") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/jackpot-history')}`}>
          <NavLink className="menu-link" to="/jackpot-history">
            <span className="menu-text">{t("Jackpot History")}</span>
            {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
          </NavLink>
        </li>
      )}
      {hasSubMenuAccess("/jackpot-sources") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/jackpot-sources')}`}>
          <NavLink className="menu-link" to="/jackpot-sources">
            <span className="menu-text">{t("Jackpot Sources")}</span>
          </NavLink>
        </li>
      )}
      {hasSubMenuAccess("/display-channels") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/display-channels')}`}>
          <NavLink className="menu-link" to="/display-channels">
            <span className="menu-text">{t("Display Channels")}</span>
          </NavLink>
        </li>)}
    </ul>
  </div>;
}

export function HeaderMenuReports({ layoutProps }) {
  const location = useLocation();
  const { t } = useTranslation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  }

  return <div
    id="kt_header_menu"
    className={`header-menu header-menu-left header-menu-mobile menu-submenu menu-submenu-classic menu-submenu-left ${layoutProps.ktMenuClasses}`}
    {...layoutProps.headerMenuAttributes}
  >
    <ul className={`menu-nav menu-subnav ${layoutProps.ulClasses}`}>
      {hasSubMenuAccess("/reports/slot-games") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/reports/slot-games')}`}>
          <NavLink className="menu-link" to="/reports/slot-games">
            <span className="menu-text">{t("Game Stats")}</span>
            {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
          </NavLink>
        </li>
      )}
      {hasSubMenuAccess("/reports/daily-reports") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/reports/daily-reports')}`}>
          <NavLink className="menu-link" to={{ pathname: "/reports/daily-reports", key: Math.round(Math.random() * 1000), menuReload: true }}>
            <span className="menu-text">{t("Daily Reports")}</span>
            {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
          </NavLink>
        </li>
      )}
      {hasSubMenuAccess("/reports/monthly-reports") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/reports/monthly-reports')}`}>
          <NavLink className="menu-link" to={{ pathname: "/reports/monthly-reports", key: Math.round(Math.random() * 1000), menuReload: true }}>
            <span className="menu-text">{t("Monthly Reports")}</span>
            {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
          </NavLink>
        </li>
      )}
      {hasSubMenuAccess("/reports/payment-transactions") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/reports/payment-transactions')}`}>
          <NavLink className="menu-link" to="/reports/payment-transactions">
            <span className="menu-text">{t("Payment Transactions")}</span>
          </NavLink>
        </li>
      )}
      {hasSubMenuAccess("/reports/collector-checkpoints") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/reports/collector-checkpoints')}`}>
          <NavLink className="menu-link" to="/reports/collector-checkpoints">
            <span className="menu-text">{t("Collector Checkpoints")}</span>
          </NavLink>
        </li>
      )}
    </ul>
  </div>;
}

export function HeaderMenuAdvanced({ layoutProps }) {
  const location = useLocation();
  const { t } = useTranslation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  }

  return <div
    id="kt_header_menu"
    className={`header-menu header-menu-left header-menu-mobile menu-submenu menu-submenu-classic menu-submenu-left ${layoutProps.ktMenuClasses}`}
    {...layoutProps.headerMenuAttributes}
  >
    <ul className={`menu-nav menu-subnav ${layoutProps.ulClasses}`}>
      {hasSubMenuAccess("/advanced/smibs") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/advanced/smibs')}`}>
          <NavLink className="menu-link" to="/advanced/smibs">
            <span className="menu-text">{t("SMIBs")}</span>
          </NavLink>
        </li>
      )}
      {hasSubMenuAccess("/advanced/checkpoints") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/advanced/checkpoints')}`}>
          <NavLink className="menu-link" to="/advanced/checkpoints">
            <span className="menu-text">{t("Checkpoints")}</span>
          </NavLink>
        </li>
      )}
      {hasSubMenuAccess("/advanced/timelog") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/advanced/timelog')}`}>
          <NavLink className="menu-link" to="/advanced/timelog">
            <span className="menu-text">{t("Timelog")}</span>
          </NavLink>
        </li>
      )}
      {/* {hasSubMenuAccess("/advanced/computed-aggregations") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/advanced/computed-aggregations')}`}>
          <NavLink className="menu-link" to="/advanced/computed-aggregations">
            <span className="menu-text">{t("Computed Aggregations")}</span>
          </NavLink>
        </li>
      )}
      {hasSubMenuAccess("/advanced/raw-aggregations") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/advanced/raw-aggregations')}`}>
          <NavLink className="menu-link" to="/advanced/raw-aggregations">
            <span className="menu-text">{t("Raw Aggregations")}</span>
          </NavLink>
        </li>
      )} */}
      {hasSubMenuAccess("/advanced/tasks") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/advanced/tasks')}`}>
          <NavLink className="menu-link" to="/advanced/tasks">
            <span className="menu-text">{t("Tasks")}</span>
          </NavLink>
        </li>
      )}
      {hasSubMenuAccess("/advanced/aggregation-rules") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/advanced/aggregation-rules')}`}>
          <NavLink className="menu-link" to="/advanced/aggregation-rules">
            <span className="menu-text">{t("Aggregation Rules")}</span>
          </NavLink>
        </li>
      )}
    </ul >
  </div >;
}

export function HeaderMenuACL({ layoutProps }) {
  const location = useLocation();
  const { t } = useTranslation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  }

  return <div
    id="kt_header_menu"
    className={`header-menu header-menu-left header-menu-mobile menu-submenu menu-submenu-classic menu-submenu-left ${layoutProps.ktMenuClasses}`}
    {...layoutProps.headerMenuAttributes}
  >
    <ul className={`menu-nav menu-subnav ${layoutProps.ulClasses}`}>
      {hasSubMenuAccess("/acl/users") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/acl/users')}`}>
          <NavLink className="menu-link" to="/acl/users">
            <span className="menu-text">{t("Users")}</span>
          </NavLink>
        </li>
      )}
      {hasSubMenuAccess("/acl/user-groups") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/acl/user-groups')}`}>
          <NavLink className="menu-link" to="/acl/user-groups">
            <span className="menu-text">{t("User Groups")}</span>
          </NavLink>
        </li>
      )}
      {hasSubMenuAccess("/acl/permissions") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/acl/permissions')}`}>
          <NavLink className="menu-link" to="/acl/permissions">
            <span className="menu-text">{t("Permissions")}</span>
          </NavLink>
        </li>
      )}
      {hasSubMenuAccess("/acl/roles") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/acl/roles')}`}>
          <NavLink className="menu-link" to="/acl/roles">
            <span className="menu-text">{t("Roles")}</span>
          </NavLink>
        </li>
      )}
      {hasSubMenuAccess("/acl/entity-groups") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/acl/entity-groups')}`}>
          <NavLink className="menu-link" to="/acl/entity-groups">
            <span className="menu-text">{t("Entity Groups")}</span>
          </NavLink>
        </li>
      )}
      {hasSubMenuAccess("/acl/rules") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/acl/rules')}`}>
          <NavLink className="menu-link" to="/acl/rules">
            <span className="menu-text">{t("ACL Rules")}</span>
          </NavLink>
        </li>
      )}
      {hasSubMenuAccess("/acl/api-tokens") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/acl/api-tokens')}`}>
          <NavLink className="menu-link" to="/acl/api-tokens">
            <span className="menu-text">{t("API Tokens")}</span>
          </NavLink>
        </li>
      )}
    </ul>
  </div>;
}

export function HeaderMenuAdmin({ layoutProps }) {
  const location = useLocation();
  const { t } = useTranslation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  }

  return <div
    id="kt_header_menu"
    className={`header-menu header-menu-left header-menu-mobile menu-submenu menu-submenu-classic menu-submenu-left ${layoutProps.ktMenuClasses}`}
    {...layoutProps.headerMenuAttributes}
  >
    <ul className={`menu-nav menu-subnav ${layoutProps.ulClasses}`}>
      {hasSubMenuAccess("/admin/columns-configurations") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/admin/columns-configurations')}`}>
          <NavLink className="menu-link" to="/admin/columns-configurations">
            <span className="menu-text">{t("Columns Configurations")}</span>
          </NavLink>
        </li>
      )}
      {hasSubMenuAccess("/admin/general-settings") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/admin/general-settings')}`}>
          <NavLink className="menu-link" to="/admin/general-settings">
            <span className="menu-text">{t("General Settings")}</span>
          </NavLink>
        </li>
      )}
    </ul >
  </div >;
}

export function HeaderMenuOperations({ layoutProps }) {
  const location = useLocation();
  const { t } = useTranslation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  }

  return <div
    id="kt_header_menu"
    className={`header-menu header-menu-left header-menu-mobile menu-submenu menu-submenu-classic menu-submenu-left ${layoutProps.ktMenuClasses}`}
    {...layoutProps.headerMenuAttributes}
  >
    <ul className={`menu-nav menu-subnav ${layoutProps.ulClasses}`}>
      {hasSubMenuAccess("/operations/remote-cashout") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/operations/remote-cashout')}`}>
          <NavLink className="menu-link" to="/operations/remote-cashout">
            <span className="menu-text">{t("Remote Cashout")}</span>
          </NavLink>
        </li>
      )}
    </ul >
  </div >;
}

export function HeaderMenuNotifications({ layoutProps }) {
  const location = useLocation();
  const { t } = useTranslation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  }

  return <div
    id="kt_header_menu"
    className={`header-menu header-menu-left header-menu-mobile menu-submenu menu-submenu-classic menu-submenu-left ${layoutProps.ktMenuClasses}`}
    {...layoutProps.headerMenuAttributes}
  >
    <ul className={`menu-nav menu-subnav ${layoutProps.ulClasses}`}>
      {hasSubMenuAccess("/notifications/list") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/notifications/list')}`}>
          <NavLink className="menu-link" to="/notifications/list">
            <span className="menu-text">{t("List")}</span>
          </NavLink>
        </li>
      )}
      {hasSubMenuAccess("/notifications/subscriptions") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/notifications/subscriptions')}`}>
          <NavLink className="menu-link" to="/notifications/subscriptions">
            <span className="menu-text">{t("Subscriptions")}</span>
          </NavLink>
        </li>
      )}
    </ul >
  </div >;
}

export function HeaderMenuPlayerExclusion({ layoutProps }) {
  const location = useLocation();
  const { t } = useTranslation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  }

  return <div
    id="kt_header_menu"
    className={`header-menu header-menu-left header-menu-mobile menu-submenu menu-submenu-classic menu-submenu-left ${layoutProps.ktMenuClasses}`}
    {...layoutProps.headerMenuAttributes}
  >
    <ul className={`menu-nav menu-subnav ${layoutProps.ulClasses}`}>
      {hasSubMenuAccess("/player-exclusion/players") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/player-exclusion/players')}`}>
          <NavLink className="menu-link" to="/player-exclusion/players">
            <span className="menu-text">{t("Players")}</span>
          </NavLink>
        </li>
      )}
      {hasSubMenuAccess("/player-exclusion/self-exclusions") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/player-exclusion/self-exclusions')}`}>
          <NavLink className="menu-link" to="/player-exclusion/self-exclusions">
            <span className="menu-text">{t("Self Exclusions")}</span>
          </NavLink>
        </li>
      )}
      {hasSubMenuAccess("/player-exclusion/player-scans") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/player-exclusion/player-scans')}`}>
          <NavLink className="menu-link" to="/player-exclusion/player-scans">
            <span className="menu-text">{t("Player Scans")}</span>
          </NavLink>
        </li>
      )}
      {hasSubMenuAccess("/player-exclusion/blacklists") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/player-exclusion/blacklists')}`}>
          <NavLink className="menu-link" to="/player-exclusion/blacklists">
            <span className="menu-text">{t("Blacklists")}</span>
          </NavLink>
        </li>
      )}
    </ul >
  </div >;
}

export function HeaderMenuGameRegistry({ layoutProps }) {
  const location = useLocation();
  const { t } = useTranslation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  }

  return <div
    id="kt_header_menu"
    className={`header-menu header-menu-left header-menu-mobile menu-submenu menu-submenu-classic menu-submenu-left ${layoutProps.ktMenuClasses}`}
    {...layoutProps.headerMenuAttributes}
  >
    <ul className={`menu-nav menu-subnav ${layoutProps.ulClasses}`}>
      {hasSubMenuAccess("/games-registry/game-profiles") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/games-registry/game-profiles')}`}>
          <NavLink className="menu-link" to="/games-registry/game-profiles">
            <span className="menu-text">{t("Game Profiles")}</span>
          </NavLink>
        </li>
      )}
      {hasSubMenuAccess("/games-registry/game-mixes") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/games-registry/game-mixes')}`}>
          <NavLink className="menu-link" to="/games-registry/game-mixes">
            <span className="menu-text">{t("Game Mixes")}</span>
          </NavLink>
        </li>
      )}
      {hasSubMenuAccess("/games-registry/games") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/games-registry/games')}`}>
          <NavLink className="menu-link" to="/games-registry/games">
            <span className="menu-text">{t("Games")}</span>
          </NavLink>
        </li>
      )}
      {hasSubMenuAccess("/games-registry/machine-types") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/games-registry/machine-types')}`}>
          <NavLink className="menu-link" to="/games-registry/machine-types">
            <span className="menu-text">{t("Machine Types")}</span>
          </NavLink>
        </li>
      )}
      {hasSubMenuAccess("/games-registry/manufacturers") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/games-registry/manufacturers')}`}>
          <NavLink className="menu-link" to="/games-registry/manufacturers">
            <span className="menu-text">{t("Manufacturers")}</span>
          </NavLink>
        </li>
      )}
      {hasSubMenuAccess("/games-registry/protocols") && (
        <li className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/games-registry/protocols')}`}>
          <NavLink className="menu-link" to="/games-registry/protocols">
            <span className="menu-text">{t("Protocols")}</span>
          </NavLink>
        </li>
      )}
    </ul >
  </div >;
}
